import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 顶部标题,
    title: '',
    // 季度id
    quarterId: '',
    // 顶部显示年度列表还是季度列表 true 代表年度 false 代表季度列表.
    listType : true,
    // 控制顶部选择季度/年度框是否显示
    seleView: true,
    loading: true,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
