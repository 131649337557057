<template>
    <div class="home">
        <div class="top">
            <!-- 顶部 -->
            <top-bar ref="topBar"></top-bar>
        </div>
        <div v-loading="loading" class="dialogClass" v-if="loading"></div>
        <div>
            <router-view/>
        </div>
    </div>
</template>

<script>

import TopBar from "@/components/top-bar.vue";

export default {
    name: 'HomeView',
    components: {TopBar},
    data() {
        return {
            topSelectVal: '',
            loading: this.$store.state.loading,
        }
    },
    watch: {
        'getLoadingStatus'(val) {
            console.log("发生变化")
            console.log(val);
            this.loading = val;
        }
    },
    computed: {
        getLoadingStatus() {
            return this.$store.state.loading;
        }
    },
    methods: {
        // 显示的列表类型
        listView(type) {
            if (type) {
                // 查询年列表
                this.$refs.topBar.findYear();
            } else {
                // 查询季度列表
                this.$refs.topBar.findQuarter();
            }
        },

    }
}
</script>

<style scoped lang="scss">
.top {
  height: 126px;
}

// 加载框的样式
.dialogClass {
  position: fixed;
  width: 100%; // 设置成满屏
  height: 100%;
  //top: 14%;
  z-index: 400; // 设置它的层级必须在选择框下
  ::v-deep .el-loading-mask { // 设置加载框的背景颜色
    background-color: #020f2a;
  }

  // 控制加载图表的位置.
  ::v-deep .el-loading-spinner {
    margin-top: -110px;
  }
}


</style>
