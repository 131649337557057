import axios from "axios";
// import cookie from 'js-cookie'

// element-ui的通知样式
// import {MessageBox, Message} from 'element-ui'

// 设置统一封装请求前缀
// const urlPrefix = "http://admin.park.vip4.vip/api/leader.leader_cockpit"
const urlPrefix = "/api"

// 创建axios实例
const service = axios.create({
    // 请求的前缀地址
    baseURL: urlPrefix,
    // timeout: 15000, // 请求超时时间
    headers:{ //设置请求头为 json格式
        'Content-Type': 'application/json'
    }
})
// http request 拦截器
service.interceptors.request.use(
    config => {

        return config
    },
    err => {
        return Promise.reject(err)
    })
// http response 拦截器
service.interceptors.response.use(
    response => {

        if (!response.data.code){
            return response.data
        }

        if (response.data.code !== 200) {

            // 异常通知
            // Message({
            //     message: response.data.message,
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            return Promise.reject(response.data)
        } else {
            return response.data
        }
    },
    error => {
        return Promise.reject(error.response)
    })
export default service