import request from "@/config/request";

/**
 * 查询季度列表
 * @returns {*}
 */
export function findQuarterList() {
    return request({
        url: '/quarter',
        method: 'POST'
    })
}

/**
 * 查询首页信息
 * @returns {*}
 */
export function findIndexData(year){
    return request({
        url: '/yearIndex',
        method: 'POST',
        data: {year: year}
    })
}

/**
 * 查询地图信息
 */
export function findMapData(){
    return request({
        url: '/mapData',
        method: 'POST'
    })
}

/**
 * 查询年度地图信息
 */
export function findYearMapData(year){
    return request({
        url: '/yearMapData',
        method: 'POST',
        data: {year: year}
    })
}

/**
 * 查询年度列表
 */
export function findYearsList() {
    return request({
        url: '/yearList',
        method: 'POST'
    })
}