<template>
    <div class="main">
        <div class="content">
            <div class="top-text">
                <span>
                    <img src="../assets/static/images/地球仪.gif" width="50" height="50">
                </span>
                <!-- 地球仪后的选择框 -->
                <span>
                     <el-select v-model="value"
                                placeholder="请选择"
                                popper-class="select"
                                :popper-append-to-body="false"
                                class="selectBg"
                                @change="change">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                      </el-select>
                </span>
            </div>
            <!-- 季度/年度选择表 -->
            <div class="bottom-select" v-show="isView">
                <!--<span>-->
                <!--    <router-link to="/" active-class="link">返回首页</router-link>-->
                <!--</span>-->
                <el-select v-model="QuarterOne"
                           placeholder="请选择"
                           ref="classRef"
                           class="selectBg"
                           @change="quarterchange">
                    <el-option
                            v-for="item in QuarterList"
                            :key="item.id"
                            :label="item.name"
                            @click.native="optionClick(item)"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>

<script>
import {findQuarterList, findYearsList} from "@/api/HomeApi";

export default {
    name: "top-bar",
    data() {
        return {
            options: [],
            // 顶部选中的文字
            value: this.$store.state.title,
            isView: this.$store.state.seleView,
            // 季度列表
            QuarterList: [],
            // 季度/年度选择的值
            QuarterOne: '',
            // 选中的季度id
            // quarterId: ''
            // 跳转的路径
            // pushPath: ''
        }
    },
    created() {
        this.init();
    },
    watch: {
        'getTitleText'(val) {
            this.value = val;
        },
        'getSeleView'(val) {
            console.log('检测' + val);

            let dom = document.querySelector(".content");
            if (!val) {
                console.log(dom);
                dom.style.height = '77px';
            } else {
                dom.style.height = '126px'
            }
            this.isView = val;
        },
    },
    computed: {
        getTitleText() {
            return this.$store.state.title;
        },
        getSeleView() {
            return this.$store.state.seleView;
        }
    },
    methods: {
        /**
         * 顶部中心发生变化
         * @param val 绑定的value
         */
        change(val) {
            if (val) {
                // this.$parent.pushRouter(val)
                // console.log(val);
                this.$router.push({path: val})
            }
        },
        /**
         * 季度列表发生变化
         * @param val 绑定的value
         * @constructor
         */
        quarterchange(val) {
            if (val && this.value != '开发区运行态势展示中心') { // 不添加 ！= 的话会触发两次查询
                // console.log(val);
                this.$store.state.quarterId = val;
            }
        },
        /**
         * 当选择框中被点击 || 被选中
         * @param val
         */
        optionClick(val){
            if (val && this.value == '开发区运行态势展示中心') {
                console.log('年度为：' + val.name);
                this.$store.state.quarterId = parseInt(val.name)
            }
        },
        /**
         * 查询季度列表.
         */
        findQuarter() {
            // 查询年度度列表
            findQuarterList().then(res => {

                // console.log(res);
                this.QuarterList = res.data;
                this.QuarterOne = res.data[0].name

                this.$store.state.quarterId = res.data[0].id;
            })
        },
        /**
         * 查询年度列表
         */
        findYear() {
            // 查询年度度列表
            findYearsList().then(res => {

                // res.data = [{year: 2023, id: 9}, {year: 2022, id: 6}, {year: 2021, id: 5}]

                // 进行从大到小排序.
                res.data.sort(this.compare("year"))

                console.log(res);
                this.QuarterList = res.data.map(item => {
                    item = {name: item.year, id: item.id}
                    return item;
                });
                this.QuarterOne = res.data[0].year

                this.$store.state.quarterId = res.data[0].id;
            })
        },
        //定义一个比较器 数组根据摸个属性进行排序
        compare( propertyName) {
            return  function( object1,  object2) {
                var value1  = object1[propertyName];
                var value2  = object2[propertyName];
                if (value2  > value1) {
                    return  1;
                }  else  if (value2  > value1) {
                    return  - 1;
                }  else {
                    return  0;
                }
            }
        },
        // 初始化选择框
        init() {

            this.findYear();

            // 初始化顶部的选项值
            this.options = [
                {
                    label: '开发区运行态势展示中心',
                    value: '/index'
                },
                {
                    label: '开发区亩均效益大数据中心',
                    value: '/permuarea'
                },
                {
                    label: '开发区税收一张图',
                    value: '/revenue'
                },
                {
                    label: '开发区土地一张图',
                    value: '/ground'
                },
                {
                    label: '开发区视频监控汇聚',
                    value: "/monitor"
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  position: fixed;
  background: #001846;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.content {
  width: 376px;
  height: 126px;
  box-sizing: border-box;
}

/* 顶部文字 */
.top-text {
  height: 50px;
  padding-top: 18px;
}

/* 设置span为块元素 */
.top-text span {
  display: inline-block;
  float: left;
}

/* 地球仪 */
.top-text span:first-child {
  /*margin-left: 6px;*/
  margin-right: 6px;
}

/* 选择框 */
.top-text span:last-child {
  margin-top: 9px;
}

/* input框样式 */
.top-text ::v-deep .el-input__inner {
  background-color: #001846;
  border: none;
  color: #2786e4;
  padding-left: 0px;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
}

.top-text ::v-deep .el-select {
  width: 100% !important;
}

.selectBg{
    /* 去除点击下拉框出现的蓝色背景块 */
    -webkit-tap-highlight-color: transparent !important;
}

/* 右侧下拉框三角 */
.top-text ::v-deep .el-input__icon {
  color: #2786e4 !important;
  font-weight: bold;
}

.bottom-select ::v-deep .el-input__icon {
  color: #2786e4 !important;
}

.bottom-select {
  width: 100%;
  /*padding: 0px 12px;*/
  padding-left: 3px;
  margin-top: 14px;
  position: relative;
  z-index: 20;
}

// 返回首页文字
.bottom-select span {
  position: absolute;
  right: 42px;
  top: 13px;
  z-index: 100;
  font-size: 12px;
}

.link {
  color: #cecece;
  text-decoration: none;
}

.bottom-select ::v-deep .el-input__inner {
  background-color: #001846;
  color: #FFFFFF;
  /*padding-left: 0px;*/
  border: #2786e4 1px solid;
  width: 369px;
}

.bottom-select ::v-deep .el-select:hover .el-input__inner {
  border: #2786e4 1px solid;
}
</style>