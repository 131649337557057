// 清除默认样式
import "reset-css";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false

import ElementUI from 'element-ui';

import VueAMap from 'vue-amap';

import 'element-ui/lib/theme-chalk/index.css';
/* 按需引入element-ui */
import {Select, Option, Progress,Tooltip, Loading,
     Collapse, CollapseItem, Dialog, Input,InputNumber, Button} from 'element-ui'

Vue.use(Select)
Vue.use(Option)
// Vue.use(Radio)
// Vue.use(RadioButton)
// Vue.use(RadioGroup)
Vue.use(Tooltip)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dialog)

Vue.use(Loading)

Vue.use(Progress)


/* 启用地图 */
Vue.use(VueAMap);
/* 初始化 */
VueAMap.initAMapApiLoader({
    /* 地图的key */
    key: 'b07985477db510255b88de7d70b5103a',
    plugin: [
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        "AMap.ControlBar"   // 缩放、尺寸控制器
    ],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4'
});

window._AMapSecurityConfig = {
    // 地图的code密码.
    securityJsCode: 'dfd4833463f505d1cb039f8499468624'
}

// 滚动列表
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)


new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
