import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
    { // 主页
        path: '/',
        name: 'home',
        component: home,
        redirect: 'index',
        children: [
            { // 首页
                path: 'index',
                name: 'index',
                component: () => import("../views/index/index.vue"),
            },
            { // 税收
                path: 'revenue',
                name: 'revenue',
                component: () => import("../views/revenue/revenue-view.vue")
            },
            { // 土地
                path: 'ground',
                name: 'ground',
                component: () => import("../views/ground/ground-view.vue")
            },
            { // 亩均
                path: 'permuarea',
                name: 'permuarea',
                component: () => import("../views/permuarea/permuarea-view.vue")
            },
            { // 视频监控.
                path: '/monitor',
                name: 'monitor',
                component: () => import("../views/monitor/monitor-view.vue")
            }
        ]
    },
    { // 企业画像
        path: '/portrait',
        name: 'portrait',
        component: () => import("../views/portrait/firm-portrait.vue")
    },

]

const router = new VueRouter({
    // 设置路由模式 默认 /#/ 修改后 /
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}


// 跳转后自动返回页面顶部
router.afterEach(() => {
    window.scrollTo(0,0);

    // 延迟两秒关闭loading加载
    setTimeout(() => {
        store.state.loading = false;
    },1500)
})

import store from '../store/index'

// 全局前置路由
router.beforeEach((to, from, next) => {

    // 开启loading加载
    store.state.loading = true;

    // 判断路由是否重复
    if (to.path == from.path) {
        console.log("取消导航")
        next(false); // 取消导航
    }

    const path = to.path;

    if (path) {

        if (store.state.seleView == false){
            store.state.seleView = true;
        }

        switch (path) {
            case '/index':
                store.state.title = "开发区运行态势展示中心";
                break;
            case '/revenue':
                store.state.title = "开发区税收一张图";
                break;
            case '/ground':
                store.state.title = '开发区土地一张图';
                break;
            case '/permuarea':
                store.state.title = '开发区亩均效益大数据中心';
                break;
            case '/monitor':
                store.state.seleView = false;
                store.state.title = '开发区视频监控汇聚';
                break;
        }
    }

    // console.log('to:' + to.path);
    // console.log('form' + from.path);

    next(); // 执行导航

})

export default router
